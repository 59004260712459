import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'

import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/zh-CN'

// import App from "./App.vue";
// import store from "./store";
import router from "./router";

import "@/ajax";

Vue.use(ElementUI, { locale })

Vue.config.productionTip = false

// new Vue({
//   el: '#app',
//   render: h => h(App),
// }).$mount('#app')

new Vue({
  el: "#app",
  router,
  render: h => h(App)
});
