import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export const constantRouterMap = [
    { path: "/", component: () => import("@/pages/home") },
    { path: "/prod/:sku", component: () => import("@/pages/qrcode") },
    { path: "/qp/:id", component: () => import("@/pages/qrcode-query") },
    { path: "/prod/zj/:sku", component: () => import("@/pages/show-zj") },
    { path: "/ai-home", component: () => import("@/pages/ai-home") },
    { path: "/dl:id", component: () => import("@/pages/get-prize") },
    { path: "/mbi", component: () => import("@/pages/ai-data") },
    { path: "/pcbi", component: () => import("@/pages/pc-ai-data") },
]

const router = new Router({
    mode: "history",
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRouterMap,
});

export default router;