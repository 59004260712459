<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "app",
  mounted() {
    document.title = '';
    // 加载配置
    // this.$store.dispatch("GetAppConfigs");
  }
};
</script>
<style>
@import url("index.css");
@import url("common.css");
</style>